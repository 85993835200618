<template>
    <div class="">
        <div class="d-flex flex-column align-items-center justify-content-center vh-100 text-center">
            <img src="@/static/logo.png" style="max-width: 300px;" />
            <img src="@/static/dog.png" style="max-width: 200px;border-radius: 5px;" class="mb-1" />
            <div class="lead text-white">You are downloading our Mongrela APK, download will start automaticaly. If download not starting, click this 
                <span class="fw-bolder" style="cursor: pointer;" @click="downloadApk()">link</span>
            to download now.</div>
        </div> 

        <img   src="@/static/dog-left-right.png" class="position-absolute w-100" style="top:0;max-width:200px" />
    </div>
</template>

<script>

export default {
    mounted(){
        this.downloadApk()
    },
    methods: {
        downloadApk(){
            const link = document.createElement('a');
            link.href = `https://bloxlimited.com/storage/mongrela.apk`;
            link.download = 'mongrela.apk';
            link.target = '_blank'
            link.click();
        }
    },
    data(){ 
        return {

        }
    }
}

</script>